@font-face {
  font-family: "restaurant-icons-1";
  src: url("icons/restaurant-icons-1.eot");
  src: url("icons/restaurant-icons-1.eot") format("embedded-opentype"),
    url("icons/restaurant-icons-1.woff2") format("woff2"),
    url("icons/restaurant-icons-1.woff") format("woff"),
    url("icons/restaurant-icons-1.ttf") format("truetype"),
    url("icons/restaurant-icons-1.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="ricon-"]:before,
[class*=" ricon-"]:before {
  font-family: "restaurant-icons-1";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.ricon-apricot:before {
  content: "\e800";
}
.ricon-banana:before {
  content: "\e801";
}
.ricon-barbecueA:before {
  content: "\e802";
}
.ricon-barbecueB:before {
  content: "\e803";
}
.ricon-beer_mug:before {
  content: "\e804";
}
.ricon-bottle:before {
  content: "\e805";
}
.ricon-bowl:before {
  content: "\e806";
}
.ricon-bread:before {
  content: "\e807";
}
.ricon-burgerA:before {
  content: "\e808";
}
.ricon-burgerB:before {
  content: "\e809";
}
.ricon-cakeA:before {
  content: "\e80a";
}
.ricon-cakeB:before {
  content: "\e80b";
}
.ricon-candyA:before {
  content: "\e80c";
}
.ricon-candyB:before {
  content: "\e80d";
}
.ricon-carrot:before {
  content: "\e80e";
}
.ricon-champagne:before {
  content: "\e80f";
}
.ricon-chefs_hat:before {
  content: "\e810";
}
.ricon-citrus:before {
  content: "\e811";
}
.ricon-coffee_2:before {
  content: "\e812";
}
.ricon-coffee:before {
  content: "\e813";
}
.ricon-coffee_1:before {
  content: "\e814";
}
.ricon-croissant_1:before {
  content: "\e815";
}
.ricon-croissant:before {
  content: "\e816";
}
.ricon-decanter:before {
  content: "\e817";
}
.ricon-egg_3:before {
  content: "\e818";
}
.ricon-egg_2:before {
  content: "\e819";
}
.ricon-egg_1:before {
  content: "\e81a";
}
.ricon-egg:before {
  content: "\e81b";
}
.ricon-fastfood:before {
  content: "\e81c";
}
.ricon-fire:before {
  content: "\e81d";
}
.ricon-fork:before {
  content: "\e81e";
}
.ricon-fruits:before {
  content: "\e81f";
}
.ricon-garlic:before {
  content: "\e820";
}
.ricon-grapes:before {
  content: "\e821";
}
.ricon-grill:before {
  content: "\e822";
}
.ricon-hazelnut:before {
  content: "\e823";
}
.ricon-icecream_1:before {
  content: "\e824";
}
.ricon-icecream:before {
  content: "\e826";
}
.ricon-kettle:before {
  content: "\e827";
}
.ricon-kettle_1:before {
  content: "\e828";
}
.ricon-kiwi:before {
  content: "\e829";
}
.ricon-knives:before {
  content: "\e82a";
}
.ricon-lemon:before {
  content: "\e82b";
}
.ricon-lemonade:before {
  content: "\e82c";
}
.ricon-mushroom:before {
  content: "\e82d";
}
.ricon-olive:before {
  content: "\e82e";
}
.ricon-pan:before {
  content: "\e82f";
}
.ricon-pear:before {
  content: "\e830";
}
.ricon-chilli:before {
  content: "\e831";
}
.ricon-pizza:before {
  content: "\e832";
}
.ricon-plate:before {
  content: "\e833";
}
.ricon-pot:before {
  content: "\e834";
}
.ricon-potholder:before {
  content: "\e835";
}
.ricon-salver:before {
  content: "\e836";
}
.ricon-sausage:before {
  content: "\e837";
}
.ricon-scales:before {
  content: "\e838";
}
.ricon-skimmer:before {
  content: "\e839";
}
.ricon-spatula:before {
  content: "\e83a";
}
.ricon-steak:before {
  content: "\e83b";
}
.ricon-tableware:before {
  content: "\e83c";
}
.ricon-tomato:before {
  content: "\e83d";
}
.ricon-turkey:before {
  content: "\e83e";
}
.ricon-wheat:before {
  content: "\e83f";
}
.ricon-whiskey:before {
  content: "\e840";
}
.ricon-wineglass:before {
  content: "\e841";
}
.ricon-apple:before {
  content: "\e842";
}
