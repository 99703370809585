@tailwind base;
@tailwind components;

html,
body,
#root {
  width: 100%;
  min-height: 100%;
}

h1,
h2,
h3,
h4 {
  @apply font-semibold leading-normal;
}

h1 {
  @apply text-3xl;
}
h2 {
  @apply text-2xl;
}
h3 {
  @apply text-xl;
}
h4 {
  @apply text-lg;
}
a {
  @apply text-primary font-medium underline cursor-pointer;
}
a.no-style {
  color: inherit;
  appearance: inherit;
  text-decoration: inherit;
  font-weight: inherit;
}
a.no-style:hover {
  color: inherit;
  appearance: inherit;
  text-decoration: inherit;
  font-weight: inherit;
}

.trans-100 {
  transition: 0.1s all;
}
.trans-200 {
  transition: 0.2s all;
}
.trans-300 {
  transition: 0.3s all;
}
.trans-400 {
  transition: 0.4s all;
}

.last-m-0 > *:last-child {
  margin: 0 !important;
}
.last-mb-0 > *:last-child {
  margin-bottom: 0 !important;
}
.last-mr-0 > *:last-child {
  margin-right: 0 !important;
}
.last-ml-0 > *:last-child {
  margin-left: 0 !important;
}
.last-mt-0 > *:last-child {
  margin-top: 0 !important;
}

.first-m-0 > *:first-child {
  margin: 0 !important;
}
.first-mb-0 > *:first-child {
  margin-bottom: 0 !important;
}
.first-mr-0 > *:first-child {
  margin-right: 0 !important;
}
.first-ml-0 > *:first-child {
  margin-left: 0 !important;
}
.first-mt-0 > *:first-child {
  margin-top: 0 !important;
}

.ql-content p {
  line-height: 1.4;
}
.ql-content br {
  margin-bottom: 10px;
}

.ql-size-mini {
  font-size: 0.5rem !important;
}
.ql-size-small {
  font-size: 0.9rem !important;
}
.ql-size-default {
  font-size: 1rem !important;
}
.ql-size-large {
  font-size: 1.5rem !important;
}
.ql-size-extra-large {
  font-size: 2rem !important;
}
.ql-size-huge {
  font-size: 2.5rem !important;
}
.leaflet-container.pointer-cursor-enabled {
  cursor: pointer;
}
