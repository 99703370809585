*,
*:before,
*:after {
  box-sizing: border-box;
}

button {
  outline: 0 !important;
}

input:-moz-placeholder {
  color: var(--gray-700);
}
input:-ms-input-placeholder {
  color: var(--gray-700);
}
input::-webkit-input-placeholder {
  color: var(--gray-700);
}
textarea:-moz-placeholder {
  color: var(--gray-700);
}
textarea:-ms-input-placeholder {
  color: var(--gray-700);
}
textarea::-webkit-input-placeholder {
  color: var(--gray-700);
}

input[type="file"] {
  font-size: 0.85rem !important;
}
